<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" ios="close-outline" md="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'selectDates.title' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-progress-bar [hidden]="!pendingRequest" type="indeterminate"></ion-progress-bar>

  <!-- Disabled Segment -->
  <ion-segment (ionChange)="segmentChanged($event)" value="route">
    <ion-segment-button value="route">
      <ion-label>{{'selectDates.route' | translate}}</ion-label>
    </ion-segment-button>
    <ion-segment-button value="stops">
      <ion-label>{{'selectDates.stops' | translate}}</ion-label>
    </ion-segment-button>
  </ion-segment>

  <ion-list>

    <ion-radio-group value="today">

      <ion-list-header>
        <ion-label>{{'selectDates.period' | translate}}</ion-label>
      </ion-list-header>

      <ion-item>
        <ion-label>{{'selectDates.today' | translate}}</ion-label>
        <ion-radio slot="start" value="today" (click)="setToday()"></ion-radio>
      </ion-item>

      <ion-item>
        <ion-label>{{'selectDates.last24h' | translate}}</ion-label>
        <ion-radio slot="start" value="last24h" (click)="setlast24h()"></ion-radio>
      </ion-item>

      <ion-item>
        <ion-label>{{'selectDates.yesterday' | translate}}</ion-label>
        <ion-radio slot="start" value="yesterday" (click)="setyesterday()"></ion-radio>
      </ion-item>

      <ion-item>
        <ion-label>{{'selectDates.thisWeek' | translate}}</ion-label>
        <ion-radio slot="start" value="thisweek" (click)="setthisweek()"></ion-radio>
      </ion-item>

      <ion-item>
        <ion-label>{{'selectDates.last7days' | translate}}</ion-label>
        <ion-radio slot="start" value="last7days" (click)="setlast7days()"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>

  <ion-list>
    <ion-item>
      <ion-label>{{'selectDates.startDate' | translate}}</ion-label>
      <ion-datetime min="1970-01-01" displayFormat="DD.MM.YYYY" value="{{startDate}}" [(ngModel)]="startDate">
      </ion-datetime>
    </ion-item>

    <ion-item>
      <ion-label>{{'selectDates.startTime' | translate}}</ion-label>
      <ion-datetime min="00:00:00" [(ngModel)]="startTime" displayFormat="HH:mm" value="{{startTime}}"></ion-datetime>
    </ion-item>

    <ion-item>
      <ion-label>{{'selectDates.endDate' | translate}}</ion-label>
      <ion-datetime min="1970-01-01" displayFormat="DD.MM.YYYY" value="{{endDate}}" [(ngModel)]="endDate">
      </ion-datetime>
    </ion-item>

    <ion-item>
      <ion-label>{{'selectDates.endTime' | translate}}</ion-label>
      <ion-datetime min="00:00:00" [(ngModel)]="endTime" displayFormat="HH:mm" value="{{endTime}}"></ion-datetime>
    </ion-item>
  </ion-list>

  <div class="ion-padding">
    <ion-button disabled="{{pendingRequest}}" (click)="getReport()" type="submit" expand="block">{{'selectDates.getReport' | translate}}</ion-button>
  </div>

</ion-content>
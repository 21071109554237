import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckTutorial } from './providers/check-tutorial.service';
import { AuthGuardService } from './services/routeGuard/auth-guard.service';
import { DataResolverService } from './resolvers/dataResolver/data-resolver.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/tutorial',
    pathMatch: 'full'
  },
  {
    path: 'account',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'login',
    canLoad: [AuthGuardService],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    canLoad: [AuthGuardService],
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'app',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'password-update',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/password-update/password-update.module').then( m => m.PasswordUpdatePageModule)
  },
  {
    path: 'device-settings',
    loadChildren: () => import('./pages/device-settings/device-settings.module').then( m => m.DeviceSettingsPageModule)
  },
  {
    path: 'device-set',
    loadChildren: () => import('./pages/device-set/device-set.module').then( m => m.DeviceSetPageModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule)
  },
  {
    path: 'report-page/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/report-page/report-page.module').then( m => m.ReportPagePageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReverseGeoCodingService {

  mapboxApiPath:string = "https://api.mapbox.com/geocoding/v5/mapbox.places/";
  accessToken:string = "pk.eyJ1Ijoia29yb3NlY3MiLCJhIjoiY2wxbDVlNzBhMDV1NTNib2I4bTRkeGtpayJ9.oEItWWxYvFvlepL5DcMz8w";

  constructor(public http: HttpClient) { }

  getAddressFromCoordinates(lat:number, lon:number):Observable<any> {

    console.log("MapBox API call!");

    let result:Observable<any>;

    result = this.http.get(this.mapboxApiPath 
      + lon.toString() + ',' 
      + lat.toString()
      + '.json?limit=1&access_token='
      + this.accessToken
    );

    return result;
  }
}

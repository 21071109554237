import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { UserData } from './providers/user-data';
import { TraccarService } from './services/traccar/traccar.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { IsDarkService } from './services/isDark/is-dark.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  loggedIn = false;
  dark = false;
  language: string = "en";
  localize: any;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private traccarService: TraccarService,
    private translate: TranslateService,
    public alertController: AlertController,
    public isDarkService: IsDarkService
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    this.checkLoginStatus();
    this.setUpTraccar();
    this.listenForLoginEvents();

    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.storage.get("isDark").then(isDark => {
        if (isDark != null) {
          this.isDarkService.setIsDark(isDark);
          this.dark = isDark;
        }
        this.configureAppLang()
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString('#1D2D44');

        this.statusBar.styleDefault();
        this.splashScreen.hide();
      });
    });
  }

  getTranslations() {
    this.translate.get(["general"]).subscribe((res: any) => {
      console.log(res);
      this.localize = res;
    });
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  async configureAppLang() {
    this.translate.setDefaultLang('sl');
    await this.storage.get('language').then((lang) => {
      if (lang) {
        this.language = lang;
        this.translate.use(lang);
        this.getTranslations();
      }
    });
  }

  public changeLanguage(): void {
    this.translate.use(this.language);
    this.storage.set("language", this.language).then(async (lang) => {

      const alert = await this.alertController.create({
        message: this.localize.general.changeLangSubHeader,
        buttons: [
          {
            text: this.localize.general.changeLater,
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              
            }
          }, {
            text: this.localize.general.changeNow,
            handler: () => {
              window.location.reload()
            }
          }
        ]
      });
  
      await alert.present();

    });
  }

  /**
   * Making use of the traccar service, sets up the
   * basepath of the service and the user credentials
   * for the Rest API
   */
  setUpTraccar() {
    this.traccarService.loadBasePathFromMemory();
    return this.userData.getUser().then(user => {
      if (user) {
        this.traccarService.setUserApiCredentials(user);
      }
    })
  }

  themeSwitcher(isDark: boolean) {
    this.isDarkService.setIsDark(isDark);
    return this.storage.set("isDark", isDark)
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.userData.logout().then(() => {
      this.traccarService.setUserApiCredentials(null);
      return this.router.navigateByUrl('/login');
    });
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }
}

<ion-app [class.dark-theme]="dark">
  <ion-split-pane contentId="main-content">

    <ion-menu contentId="main-content">
      <ion-content>
        <ion-list *ngIf="loggedIn" lines="none">
          <ion-list-header>
            {{'sideBar.administration' | translate}}
          </ion-list-header>
          
          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/app/tabs/devices" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" src="/assets/icons/icon-devices.svg"></ion-icon>
              <ion-label>
                {{'sideBar.myDevices' | translate}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/app/tabs/map" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="map-outline"></ion-icon>
              <ion-label>
                {{'sideBar.map' | translate}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/app/tabs/reports" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" src="/assets/icons/icon-report.svg"></ion-icon>
              <ion-label>
                {{'sideBar.reports' | translate}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        
        </ion-list>

        <ion-list *ngIf="loggedIn" lines="none">
          <ion-list-header>
            {{'sideBar.settings' | translate}}
          </ion-list-header>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/app/tabs/device-settings" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" src="/assets/icons/icon-settings.svg"></ion-icon>
              <ion-label>
                {{'sideBar.deviceSettings' | translate}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-list *ngIf="loggedIn" lines="none">
          <ion-list-header>
            {{'sideBar.account' | translate}}
          </ion-list-header>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/app/tabs/account" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" src="/assets/icons/icon-account.svg"></ion-icon>
              <ion-label>
                {{'sideBar.account' | translate}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-item>
            <ion-icon slot="start" src="/assets/icons/icon-darkmode.svg"></ion-icon>
            <ion-label>
              {{'sideBar.darkMode' | translate}}
            </ion-label>
            <ion-toggle (click)="themeSwitcher(!dark)" [(ngModel)]="dark"></ion-toggle>
          </ion-item>


        </ion-list>

        <ion-list *ngIf="!loggedIn" lines="none">
          <ion-list-header>
            {{'sideBar.account' | translate}}
          </ion-list-header>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/login" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" src="/assets/icons/icon-login.svg"></ion-icon>
              <ion-label>
                {{'sideBar.login' | translate}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/signup" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" src="/assets/icons/icon-signup.svg"></ion-icon>
              <ion-label>
                {{'sideBar.signup' | translate}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-item>
            <ion-icon slot="start" src="/assets/icons/icon-darkmode.svg"></ion-icon>
            <ion-label>
              {{'sideBar.darkMode' | translate}}
            </ion-label>
            <ion-toggle (click)="themeSwitcher(!dark)" [(ngModel)]="dark"></ion-toggle>
          </ion-item>
        </ion-list>

        <ion-list lines="none">
          <ion-list-header>
            {{'sideBar.language' | translate}}
          </ion-list-header>
          <ion-item>
            <ion-icon slot="start" src="/assets/icons/icon-languages.svg"></ion-icon>
            <ion-select [(ngModel)]="language" [value]="language" (ionChange)="changeLanguage()">
              <ion-select-option value="sl">Slovenščina</ion-select-option>
              <ion-select-option value="en">English</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>

        <ion-list lines="none">
          <ion-list-header>
            {{'sideBar.tutorial' | translate}}
          </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item button (click)="openTutorial()" detail="false">
              <ion-icon slot="start" src="/assets/icons/icon-tutorial.svg"></ion-icon>
              <ion-label>{{'sideBar.showTutorial' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>

</ion-app>

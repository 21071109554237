import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IsDarkService {

  isDark: Boolean = false;

  constructor() { }

  getIsDark(): Boolean {
    return this.isDark;
  }

  setIsDark(state: Boolean) {
    this.isDark = state;
  }
}

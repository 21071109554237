import { Injectable } from '@angular/core';
import { DefaultService } from './api/default.service';
import { User } from './model/user';
import { Device } from './model/device';
import { Storage } from '@ionic/storage';

/**
 * This service allows an easier interaction with the traccar API and provides some custom functions 
 * for configuration/management
 */
@Injectable({
  providedIn: 'root'
})
export class TraccarService {

  devices: Device[];
  // Default service URL that will be displayed/used in the app
  defaultBasePath: string = 'https://traccar.sledilec.si/api';
  

  constructor(
    private apiGateway: DefaultService,
    public storage: Storage,
  ) { }

  /**
   * Sets the list of devices
   * @param devices List of devices
   */
  setDevices(devices: Device[]) {
    this.devices = devices;
  }

  /**
   * Returns the list of devices
   */
  getDevices(): Device[] {
    return this.devices;
  }

  /**
   * Asynchronously refresh the list of devices by 
   * performing a request to the traccar API
   */
  async refreshDevices() {
    this.devices = await this.apiGateway.devicesGet(undefined, undefined, undefined, undefined, "body", undefined).toPromise();
  }

  /**
   * Given a User object, sets the the credentials for the
   * traccar API
   * @param user 
   */
  setUserApiCredentials(user: User) {
    if (user != null) {
      this.apiGateway.configuration.username = user.email;
      this.apiGateway.configuration.password = user.password;
    } else {
      this.apiGateway.configuration.username = null;
      this.apiGateway.configuration.password = null;
    }
    return;
  }

  /**
   * Sets the traccar basepath to the given value and
   * stores it in memory
   * @param basePath basepath to use 
   */
  setBasePath(basePath: string): Promise<any> {
    return this.storage.set('basepath', basePath).then((value) => {
      this.apiGateway.basePath = value;
    });
  }

  /**
   * Returns the stored traccar basepath. If not value found,
   * the functions returns the address of one of the demo servers
   * of traccar
   */
  getBasePath(): Promise<string> {
    return this.storage.get('basepath').then((value) => {
      if (value) {
        //return value;
        return this.defaultBasePath; // FIXME: only a temporary solution, make this correctly
      } else {
        return this.defaultBasePath;
      };
    });
  }

  /**
   * Sets the traccar basepath to the one stored in memory
   * If no value found, it is set to the address of one of
   * the demo servers of traccar
   */
  loadBasePathFromMemory() {
    return this.getBasePath().then(value => {
      this.apiGateway.basePath = value;
    })
  }

}

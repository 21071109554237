import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Device } from '../../services/traccar/model/device';
import { Position } from '../../services/traccar/model/position';

@Component({
  selector: 'app-position-details',
  templateUrl: './position-details.page.html',
  styleUrls: ['./position-details.page.scss'],
})
export class PositionDetailsPage {

   @Input() position: Position;
   @Input() device: Device;

  constructor(public modalController: ModalController) { }

    /**
   *  Closes this modal window
   */
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  knToKmh(speed:number):number {
    return (speed*1.852);
  }
  

}

<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" ios="close-outline" md="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'positionDetails.title' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-card>
    <ion-card-header>
      <ion-card-title>{{device.name}}</ion-card-title> 
      <!-- <ion-card-subtitle>{{device.id}}</ion-card-subtitle> -->
    </ion-card-header>

    <ion-card-content>
      {{'positionDetails.cardTitle' | translate}}
    </ion-card-content>

    <ion-item class="ion-activated">
      <ion-label>{{'positionDetails.coordinates' | translate}}: {{position.latitude}}, {{position.longitude}}</ion-label>
      <ion-icon name="pin-outline" slot="start"></ion-icon>
    </ion-item>

    <ion-item class="ion-activated">
      <ion-label>{{'positionDetails.speed' | translate}}: {{ knToKmh(position.speed).toFixed(2) }} km/h</ion-label>
      <ion-icon name="speedometer-outline" slot="start"></ion-icon>
    </ion-item>

    <!--
    <ion-item class="ion-activated">
      <ion-label>{{'positionDetails.course' | translate}}: {{ position.course.toFixed(2) }} </ion-label>
      <ion-icon name="compass-outline" slot="start"></ion-icon>
    </ion-item>
    -->

    <!--
    <ion-item class="ion-activated">
      <ion-label>{{'positionDetails.accuracy' | translate}}: {{ position.accuracy.toFixed(2) }} m</ion-label>
      <ion-icon name="disc-outline" slot="start"></ion-icon>
    </ion-item>
    -->

    <!--
    <ion-item class="ion-activated">
      <ion-label>{{'positionDetails.valid' | translate}}: {{ position.valid }} </ion-label>
      <ion-icon name="alert-outline" slot="start"></ion-icon>
    </ion-item>
    -->

    <!--
    <ion-item class="ion-activated">
      <ion-label>{{'positionDetails.outdated' | translate}}: {{ position.outdated }} </ion-label>
      <ion-icon name="calendar-outline" slot="start"></ion-icon>
    </ion-item>
    -->

    <!--
    <ion-item class="ion-activated">
      <ion-label>{{'positionDetails.protocol' | translate}}: {{ position.protocol }} </ion-label>
      <ion-icon name="cog-outline" slot="start"></ion-icon>
    </ion-item>
    -->

    <ion-item [hidden]="position.address === null" class="ion-activated">
      <ion-label>{{'positionDetails.address' | translate}}: {{ position.address }} </ion-label>
      <ion-icon name="home-outline" slot="start"></ion-icon>
    </ion-item>

    <!--
    <ion-item class="ion-activated">
      <ion-label>{{'positionDetails.deviceTime' | translate}}: {{ position.deviceTime | date :'medium' }} </ion-label>
      <ion-icon name="time-outline" slot="start"></ion-icon>
    </ion-item>
    -->

    <!--
    <ion-item class="ion-activated">
      <ion-label>{{'positionDetails.fixTime' | translate}}: {{ position.fixTime | date :'medium' }} </ion-label>
      <ion-icon name="stopwatch-outline" slot="start"></ion-icon>
    </ion-item>
    -->

    <!--
    <ion-item class="ion-activated">
      <ion-label>{{'positionDetails.serverTime' | translate}}: {{ position.serverTime | date :'medium' }} </ion-label>
      <ion-icon name="server-outline" slot="start"></ion-icon>
    </ion-item>
    -->

  </ion-card>

</ion-content>
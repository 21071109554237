import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';


/**
 * intercepts the http requests & responses. In this case, it is used to provide global error
 * handling
 */
@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  localize: any;

  constructor(
    public alertController: AlertController,
    private translateService: TranslateService
  ) {
    this.getTranslations();
  }

  getTranslations() {
    this.translateService.get(["interceptor", "general"]).subscribe((res: any) => {
      this.localize = res;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          console.error(error);
          this.presentAlert(error);
          return throwError(errorMessage);
        })
      )
  }

  async presentAlert(error: HttpErrorResponse) {

    let message: string;
    switch (error.status) {
      case 400: {

        if (error.error.includes("Duplicate entry")) {
          let result: string[] = (error.error.match(/Duplicate entry '(.+?)' for key '(.+?)'/));
          this.translateService.get('interceptor.duplicateEntry', { field_value: result[1], field_name: result[2] }).subscribe((res: string) => {
            message = res;
          });
        } else {
          message = this.localize.interceptor.wrongRequest;
        }
        break;
      }
      case 401: {
        message =
          this.localize.interceptor.unauthorized;
        break;
      }
      case 500: {
        message = this.localize.interceptor.internalError;
        break;
      }
      default: {
        message = error.message;
        break;
      }
    }

    const alert = await this.alertController.create({
      header: this.localize.general.alertHeader,
      subHeader: this.localize.general.alertSubHeader,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }
}

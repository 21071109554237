import { Component, OnInit, Input } from '@angular/core';
import { startOfToday, format, subHours, startOfYesterday, endOfYesterday, startOfWeek, subDays, parse, isBefore, formatISO } from 'date-fns'
import { DefaultService } from '../../services/traccar/api/default.service';
import { Device } from '../../services/traccar/model/device';
import { Position } from '../../services/traccar/model/position';
import { Event } from '../../services/traccar/model/event';
import { ReportStops } from '../../services/traccar/model/reportStops';
import { ModalController, AlertController } from '@ionic/angular';
import { TraccarService } from '../../services/traccar/traccar.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-dates',
  templateUrl: './select-dates.page.html',
  styleUrls: ['./select-dates.page.scss'],
})
export class SelectDatesPage implements OnInit {

  @Input() device: Device;
  positions: Position[] = [];
  events: Event[] = [];
  reportStops: ReportStops[] = [];
  basepath: string = '';
  localize: any;

  constructor(
    private traccarApi: DefaultService,
    public modalController: ModalController,
    private traccarService: TraccarService,
    private http: HttpClient,
    public alertController: AlertController,
    private translateService: TranslateService,
  ) {
    this.getTranslations();
  }

  startDate: string;
  startTime: string
  endDate: string;
  endTime: string;
  pendingRequest: boolean = false;
  reportType: string = "route"

  ngOnInit() {
    this.setToday();
    this.getCurrentServer();
  }

  getTranslations() {
    this.translateService.get(["selectDates", "general"]).subscribe((res: any) => {
      this.localize = res;
    });
  }

  /**
   * Detects changes in the type of report
   * @param ev
   */
  segmentChanged(ev: any) {
    this.reportType = ev.detail.value;
  }

  getCurrentServer() {
    this.traccarService.getBasePath().then(basepath => {
      this.basepath = basepath
    })
  }

  /**
  *  Closes this modal window
  */
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true,
      'positions': this.positions,
      'events': this.events,
      'reportType': this.reportType,
      'reportStops': this.reportStops
    });
  }

  setToday() {
    this.endDate = format(new Date(), "yyyy-MM-dd");
    this.endTime = format(new Date(), "HH:mm");
    this.startDate = format(startOfToday(), "yyyy-MM-dd");
    this.startTime = format(startOfToday(), "HH:mm");
  }

  setlast24h() {
    this.endDate = format(new Date(), "yyyy-MM-dd");
    this.endTime = format(new Date(), "HH:mm");
    this.startDate = format(subHours(new Date(), 24), "yyyy-MM-dd");
    this.startTime = format(subHours(new Date(), 24), "HH:mm");
  }

  setyesterday() {
    this.endDate = format(endOfYesterday(), "yyyy-MM-dd");
    this.endTime = format(endOfYesterday(), "HH:mm");
    this.startDate = format(startOfYesterday(), "yyyy-MM-dd");
    this.startTime = format(startOfYesterday(), "HH:mm");
  }

  setthisweek() {
    this.endDate = format(new Date(), "yyyy-MM-dd");
    this.endTime = format(new Date(), "HH:mm");
    this.startDate = format(startOfWeek(new Date()), "yyyy-MM-dd");
    this.startTime = format(startOfWeek(new Date()), "HH:mm");
  }

  setlast7days() {
    this.endDate = format(new Date(), "yyyy-MM-dd");
    this.endTime = format(new Date(), "HH:mm");
    this.startDate = format(subDays(new Date(), 7), "yyyy-MM-dd");
    this.startTime = format(subDays(new Date(), 7), "HH:mm");
  }

  /**
   * Makes a final verification of the given dates and generates the report
   */
  getReport() {


    this.startDate = format(new Date(this.startDate), "yyyy-MM-dd");
    this.endDate = format(new Date(this.endDate), "yyyy-MM-dd");

    let startDateTime: Date = parse(this.startDate + " " + this.startTime, 'yyyy-MM-dd HH:mm', new Date());
    let endDateTime: Date = parse(this.endDate + " " + this.endTime, 'yyyy-MM-dd HH:mm', new Date());

    if (isBefore(startDateTime, endDateTime)) {

      if (this.reportType === "route") {
        this.getRouteReport(startDateTime, endDateTime);
      }

      if (this.reportType === "events") {
        this.getEventsReport(startDateTime, endDateTime);
      }

      if (this.reportType === "stops") {
        this.getStopsReport(startDateTime, endDateTime);
      }

    } else {
      this.presentAlert(this.localize.selectDates.wrongDateTimeRange);
    }

  }

  /**
   * Get the device positions for the given date range
   * @param startDateTime 
   * @param endDateTime 
   */
  getRouteReport(startDateTime: Date, endDateTime: Date) {

    this.pendingRequest = true;
    this.traccarApi.reportsRouteGet(new Date(formatISO(startDateTime)), new Date(formatISO(endDateTime)), [this.device.id], undefined, "body", undefined)
      .subscribe(positions => {
        console.log(positions);

        if (positions.length === 0) {
          this.presentAlert(this.localize.selectDates.noPositionsError);
        } else {
          this.pendingRequest = false;
          this.positions = positions;
          this.dismiss();
        }
      }, err => {
        console.error(err);
        this.pendingRequest = false;
      }, () => {
        this.pendingRequest = false;
      });
  }

  /**
   * Get the device events for the given date range
   * @param startDateTime 
   * @param endDateTime 
   */
  async getEventsReport(startDateTime: Date, endDateTime: Date) {

    this.pendingRequest = true;
    let events: Event[] = await this.traccarApi.reportsEventsGet(new Date(formatISO(startDateTime)), new Date(formatISO(endDateTime)), [this.device.id], undefined, ["allEvents"], "body", undefined).toPromise();
    console.log(events);
    this.events = events;
    if (events.length === 0) {
      this.presentAlert(this.localize.selectDates.noEventsError);
      this.pendingRequest = false;
    } else {

      // Each event is linked to a position id, now we get the position associated with each event to be able
      // to locate events on the map
      let ids: number[] = [];
      events.forEach(event => {
        if (event.positionId != 0) {
          ids.push(event.positionId);
        }
      });

      if (ids.length > 0) {
        this.getPositions(ids);
      } else {
        this.presentAlert(this.localize.selectDates.eventsNoPos);
        this.pendingRequest = false;
      }
    }

  }

  /**
   * Gets the device stops for the given date range
   * @param startDateTime 
   * @param endDateTime 
   */
  async getStopsReport(startDateTime: Date, endDateTime: Date) {

    this.pendingRequest = true;
    let stops: ReportStops[] = await this.traccarApi.reportsStopsGet(startDateTime, endDateTime, [this.device.id], undefined, "body", undefined).toPromise();
    console.log(stops);
    this.reportStops = stops;

    if (stops.length === 0) {
      this.presentAlert(this.localize.selectDates.noStopsError);
      this.pendingRequest = false;
    } else {

      // Each event is linked to a position id, now we get the position associated with each event to be able
      // to locate events on the map
      let ids: number[] = [];
      stops.forEach(stop => {
        if (stop['positionId'] != 0) {
          ids.push(stop['positionId']);
        }
      });

      if (ids.length > 0) {
        this.getPositions(ids);
      } else {
        this.presentAlert(this.localize.selectDates.stopsNoPos);
        this.pendingRequest = false;
      }
    }

  }

  /**
  * For some stupid reason, the traccar api does not allow me to get several positions using their id with
  * a single API call (despite what the documents say, I cannot send an array of ids), so I have to assemble
  * the request by myself using http
  */
  getPositions(ids: number[]) {

    let finalIds: string[] = [];
    ids.forEach(id => {
      finalIds.push("id=" + id.toString());
    });

    return this.http.get<Position[]>(this.basepath + '/positions?' + finalIds.join("&"), {
      observe: "response",
      headers: { 'Authorization': 'Basic ' + btoa(this.traccarApi.configuration.username + ':' + this.traccarApi.configuration.password) }
    }).subscribe(async (response) => {
      console.log(response)
      this.pendingRequest = false;
      this.positions = response.body;
      this.dismiss();
    }, (error) => {
      console.error(error);
      this.pendingRequest = false;
    }, () => {
      this.pendingRequest = false;
    });
  }

  async presentAlert(error: string) {
    const alert = await this.alertController.create({
      header: this.localize.general.alertHeader,
      subHeader: this.localize.general.alertSubHeader,
      message: error,
      buttons: ['OK']
    });

    await alert.present();
  }

}
